.about {
    padding: 100px 0;

    @media screen and (max-width: 845px) {
        padding: 60px 0; }

    .wrapper-md {
        display: flex;
        align-items: center;

        @media screen and (max-width: 845px) {
            flex-direction: column; } }

    &__image {
        margin-right: 80px;
        width: 350px;
        height: 350px;
        flex: none;

        @media screen and (max-width: 845px) {
            margin-right: 0; }

        @media screen and (max-width: 380px) {
            width: 90%;
            margin: 0 auto;
            height: auto; }

        img {
            max-width: 100%; } }

    &__content {

        @media screen and (max-width: 845px) {
            margin-top: 30px;
            text-align: center; } }

    &__title {
        font-size: 38px;
        font-weight: 500;
        margin-bottom: 5px;

        @media screen and (max-width: 500px) {
            font-size: 32px; } }

    &__subtitle {
        font-size: 15px;
        color: #9a9a9a; }

    &__desc {
        margin-top: 30px;

        @media screen and (min-width: 500px) and (max-width: 845px) {
            width: 80%;
            margin-left: auto;
            margin-right: auto; }

        p {
            color: $text_color;
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 15px;

            @media screen and (max-width: 500px) {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 10px; } } } }
