body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6,
pre, code, form, fieldset, legend, figure, input, button,
textarea, select, p, blockquote, th, td, menu, section, header {
    margin: 0;
    padding: 0; }

table {
    border-collapse: collapse;
    border-spacing: 0; }

fieldset, img {
    border: 0; }

address, button, caption, cite, code, dfn, em, input,
optgroup, option, select, strong, textarea, th, var {
    font: inherit; }

button, input, select, textarea {
    vertical-align: baseline; }

del, ins {
    text-decoration: none; }

textarea {
    resize: vertical;
    overflow: auto;
    vertical-align: top; }

li {
    list-style: none;
    list-style-image: none; }

caption, th {
    text-align: left; }

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal; }

q:before, q:after {
    content: ''; }

abbr, acronym {
    border: 0;
    font-variant: normal; }

sup, sub {
    vertical-align: baseline; }

legend {
    color: #000; }

input, a {
    outline: none; }

strong {
    font-weight: bold; }

img {
    vertical-align: bottom;
    -ms-interpolation-mode: bicubic; }

audio:not([controls]) {
    display: none;
    height: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block; }

html {
    cursor: default;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%; }

a:focus {
    outline: thin dotted; }

a:active, a:hover {
    outline: 0; }

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }

sup {
    top: -0.5em; }

sub {
    bottom: -0.25em; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    *overflow: visible; }

button[disabled], html input[disabled] {
    cursor: default; }

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px; }

* {
    box-sizing: border-box; }

abbr {
    cursor: help; }

input::-moz-focus-inner {
    border: 0; }

object, embed {
    outline: 0; }
