$fontsize: 16px;
$radius: 3px;
$height: 52px;
$boxshadow: 0 2px 10px rgba(#000, 0.1);
$color: #fff;
$padding: 0 30px;

.btn {
    outline: none;
    border: none;
    display: inline-flex;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    transition: all ease 0.3s;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: $fontsize;
    border-radius: $radius;
    height: $height;
    line-height: $height;
    box-shadow: $boxshadow;
    color: $color;
    padding: $padding;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        opacity: 0.9; }

    &:active {
        position: relative;
        top: 1px; }

    &--primary {
        background: linear-gradient(90deg, $primary_color 0%, lighten($primary_color, 10%) 100%);
        min-width: 220px; } }
