@keyframes kenburns {
    0% {
        transform: scale(1); }
    50% {
        transform: scale(1.2); } }

@keyframes scroll-down {
    0% {
        bottom: 50px; }
    50% {
        bottom: 40px; } }

@keyframes rotator {
    to {
        transform: rotate(360deg); } }
