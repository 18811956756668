.grid {
    padding: 60px 0;

    .wrapper {
        @media screen and (max-width: 800px) {
            padding: 0; } }

    &__heading {}


    &__list {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px; }


    &__item {
        width: 33%;
        max-width: 380px;
        padding: 3%;

        @media screen and (max-width: 800px) {
            width: 45%;
            max-width: none; }

        @media screen and (max-width: 580px) {
            width: 100%;
            padding: 0 60px;
            margin-bottom: 60px; } }


    &__image {
        height: 200px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%; } }

    &__title {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;

        @media screen and (min-width: 800px) and (max-width: 1000px) {
            font-size: 14px;
            white-space: normal; } }

    &__desc {} }
