.cta {
    background-color: $primary_dark;
    padding: 100px 0;
    color: #fff;
    text-align: center;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 750px) {
        padding: 70px 0; }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        @include image('cover.jpg');
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        opacity: 0.4; }

    &--kenburns &__image {
        animation: kenburns 60s linear infinite; }

    .wrapper {
        position: relative;
        z-index: 1; }

    &__heading {
        font-size: 36px;
        font-weight: 500;

        @media screen and (max-width: 500px) {
            font-size: 32px; }

        strong {
            font-weight: bold;
            text-transform: uppercase; } }

    &__text {
        font-size: 16px;
        margin: 15px 0 50px; } }
