.wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;

    @media screen and (max-width: 1230px) {
        width: 100%; } }

.wrapper-md {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1030px) {
        width: 100%;
        padding: 0 30px; } }
