.static {
    color: #1C2B42;

    h1 {
        font-size: 40px;
        line-height: 42px;
        font-weight: bold;
        margin: 20px 0 30px; }

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin: 20px 0 10px; }

    h3 {
        font-size: 20px;
        font-weight: bold;
        margin: 20px 0; }

    p {
        color: #1C2B42;
        line-height: 24px;
        margin: 20px 0; }

    strong {
        font-weight: bold; }

    ul {
        margin: 10px 0;
        padding-left: 30px;

        li {
            margin: 10px 0;
            list-style-type: disc; } } }
