.cover-form {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    min-height: 550px;
    background-color: $primary_dark;

    @media screen and (max-width: 800px) {
        height: auto; }

    &__image {
        @include image('cover2.jpg');
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;
        background-position: center center;

        &:after {
            width: 100%;
            height: 100%;
            content: '';
            display: block;
            background: linear-gradient(0deg, rgba(#000, 0.2), rgba(#000, 0));
            position: absolute;
            z-index: 1; }

        & > div {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center center; } }

    &--kenburns &__image {
        animation: kenburns 60s linear infinite; }

    .wrapper {
        height: 100%; }

    &__section {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        color: #fff;
        z-index: 1;

        @media screen and (max-width: 800px) {
            flex-direction: column; } }

    &__content {
        padding-right: 50px;

        @media screen and (max-width: 800px) {
            padding-right: 0;
            margin: 60px 0; } }

    &__heading {
        font-size: 46px;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 700px;

        @media screen and (max-width: 800px) {
            text-align: center; }

        @media screen and (max-width: 720px) {
            font-size: 38px; }

        @media screen and (max-width: 500px) {
            font-size: 28px; } }

    &__desc {
        text-align: left;
        font-size: 18px;
        line-height: 26px;
        margin-top: 30px;
        opacity: 0.8;
        width: 80%;

        @media screen and (max-width: 800px) {
            text-align: center;
            width: 100%; }

        @media screen and (max-width: 500px) {
            font-size: 15px; } }

    &__form {
        flex: none;
        width: 400px;
        background: #fff;
        padding: 35px;
        color: #000;
        border-radius: 4px;

        @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 30px; }

        &-title {
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 19px;
            margin-bottom: 10px; }

        &-desc {
            text-align: center;
            font-size: 13px;
            line-height: 17px;
            color: #7a7a7a; }

        .form {
            margin-top: 30px;
            margin-bottom: 10px; }

        .spinner {
            @include loader(30px, $primary_color); } }

    .form__message {
        color: $primary_color;
        text-align: center;
        width: 100%;
        margin-top: 10px; } }
