.features {
    padding: 60px 0;

    .wrapper {
        @media screen and (max-width: 800px) {
            padding: 0; } }

    &__heading {
        text-align: center;
        font-size: 36px;
        font-weight: 500; }

    &__list {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px; }

    &__item {
        width: 33%;
        max-width: 380px;
        padding: 3%;

        @media screen and (max-width: 800px) {
            width: 45%;
            max-width: none; }

        @media screen and (max-width: 580px) {
            width: 100%;
            padding: 0 60px;
            margin-bottom: 60px; } }

    &__icon {
        font-size: 48px;
        color: $primary_color;
        text-align: center; }

    &__title {
        text-align: center;
        font-size: 20px;
        color: #000;
        margin: 20px 0 15px; }

    &__desc {
        text-align: center;
        color: #7e7e7e;
        font-size: 13px; } }
