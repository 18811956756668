@import 'utils/reset.sass';
@import 'utils/colors.sass';
@import 'utils/mixins.sass';
@import 'utils/layout.sass';
@import 'utils/buttons.sass';
@import 'utils/animations.sass';
@import 'utils/spinners.sass';
@import 'utils/fonts.scss';

// Vendor
@import 'vendor/jquery.fancybox.scss';


html {
    -webkit-font-smoothing: antialiased;
    background #fff {}
    cursor: default;
    min-width: 320px;
    color: $text_color;
    font-size: 16px;
    line-height: normal;
    font-weight: normal; }

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column; }

body, button, input, textarea {
    font-family: "Rubik"; }

a, button {
    text-decoration: none;
    color: $primary_color;
    transition: all ease 0.3s;

    &:focus {
        outline: 0 !important; } }

.page-content {
    flex: 1; }

hr {
    background: #EEEEEE;
    height: 1px;
    display: block;
    border: none;
    width: 100%; }

h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    color: #3b3b3b; }

// Abstract
@import 'components/static.sass';
@import 'components/forms.sass';
@import 'components/popup.sass';

// Components
@import 'components/header.sass';
@import 'components/cover.sass';
@import 'components/about.sass';
@import 'components/features.sass';
@import 'components/cta.sass';
@import 'components/grid.sass';
@import 'components/labels.sass';
@import 'components/map.sass';
@import 'components/contacts.sass';
@import 'components/cover-form.sass';
