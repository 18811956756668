.popup {
    display: none;
    max-width: 400px;
    width: 80%;
    padding: 35px;
    border-radius: 4px;

    &__content {
        border-radius: 4px; }

    &__heading {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 19px;
        margin-bottom: 10px; }

    &__desc {
        text-align: center;
        font-size: 13px;
        line-height: 17px;
        color: #7a7a7a; }

    .form {
        margin-top: 30px;

        .spinner {
            @include loader(50px, $primary_color); } }

    .form__message {
        color: $text_color;
        text-align: center;
        width: 100%;
        margin: 30px 0 20px;
        min-height: 0;
        line-height: 0; } }
