.labels {
    padding: 60px 0;

    .wrapper {
        @media screen and (max-width: 800px) {
            padding: 0; } }

    &__heading {}


    &__list {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px; }

    &__item {
        width: 25%;
        max-width: 285px;
        padding: 2%;

        @media screen and (max-width: 800px) {
            width: 45%;
            max-width: none; }

        @media screen and (max-width: 580px) {
            width: 100%;
            padding: 0 60px;
            margin-bottom: 60px; } }

    &__image {
        height: 90px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%; } }

    &__more {
        text-align: center;
        font-style: italic;
        color: #777;
        margin: 20px 0; } }
