.contacts {
    padding: 60px 0;

    &__list {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }

    &__item {
        text-align: center;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0; } }

    &__icon {
        font-size: 38px;
        color: $primary_color;
        text-align: center;
        margin-bottom: 10px; }

    &__content {
        font-size: 21px; }

    &__subtext {
        margin-bottom: 7px;

        &--light {
            color: #999; }

        &--note {
            margin-top: 20px;
            font-size: 90%; } }

    &__text {

        strong {
            color: $primary_color; } } }
