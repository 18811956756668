.header {
    height: 120px;
    position: absolute;
    width: 100%;
    z-index: 10;

    @media screen and (max-width: 600px) {
        height: auto;
        padding: 30px 0; }

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @media screen and (max-width: 600px) {
            flex-direction: column; } }

    &__logo {
        display: block;

        &:hover {
            opacity: 0.7; }

        @media screen and (max-width: 600px) {
            margin-bottom: 20px; } }

    &__phone {
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;

        i {
            margin-right: 8px;
            font-size: 19px; } } }
