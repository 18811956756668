.form {
    display: flex;
    position: relative;

    &__message {
        text-align: center;
        color: #fff;
        min-height: 52px;
        line-height: 52px;
        border: 1px solid rgba(#fff, 0.15);
        padding: 0 30px;
        border-radius: 110px;
        font-size: 14px;
        display: none;

        &.is-showed {
            display: inline-block; } }

    .spinner {
        @include loader(30px, #fff);
        z-index: 1;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -14px;
        opacity: 0;
        visibility: hidden; }

    &.is-sending .spinner {
        opacity: 1;
        visibility: visible; }

    &--horizontal {
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: 750px) {
            flex-direction: column;
            max-width: 560px;
            margin: 0 auto; } }

    &--vertical {
        flex-direction: column;
        flex-wrap: wrap; }

    &__field {
        position: relative;
        transition: all ease 0.3s;

        .is-sending & {
            opacity: 0.05; }

        .form--horizontal & {
            width: 100%;
            margin: 0 10px;

            @media screen and (max-width: 750px) {
                margin: 0 0 30px; } }

        .form--vertical & {
            width: 100%;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0; } }

        &--action {
            text-align: center;

            .form--horizontal & {
                width: 70%;

                @media screen and (max-width: 750px) {
                    width: 50%;
                    margin: 0 auto; } }

            .form--horizontal & button {
                width: 100%; } } }


    &__label {}


    &__input {
        width: 100%;
        height: 52px;
        border-radius: 4px;
        border: 1px solid #d0d0d0;
        transition: all ease 0.3s;
        padding: 0 20px;
        background: #fff;
        appearance: none;

        &:focus {
            border-color: $primary_color; }

        &.error {
            border-color: red;
            background-color: #fdf4f4; } }

    &--horizontal label.error {
        color: red;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        padding: 0 20px;
        display: block;
        position: absolute;
        top: 60px;
        width: 100%;
        text-align: left;

        @media screen and (max-width: 750px) {
            text-align: center;
            position: static;
            margin-top: 10px;
            margin-bottom: -10px; } }

    &--vertical label.error {
        color: red;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        padding: 0 20px;
        display: block;
        margin-top: 10px;
        margin-bottom: -5px; } }
